module.exports = {
  agile: 'https://static.thenounproject.com/png/1211690-200.png',
  css3: 'https://cdn1.iconfinder.com/data/icons/logotypes/32/badge-css-3-512.png',
  express: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Expressjs.png/220px-Expressjs.png',
  github: 'https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png',
  html5: 'https://cdn0.iconfinder.com/data/icons/social-flat-rounded-rects/512/html5-256.png',
  javascript: 'https://cdn.worldvectorlogo.com/logos/javascript.svg',
  jest: 'https://cdn.worldvectorlogo.com/logos/jest-0.svg',
  jQuery: 'https://cdn3.iconfinder.com/data/icons/popular-services-brands/512/jquery-256.png',
  mongoDB: 'https://img.icons8.com/color/180/mongodb.png',
  nodeJS: 'https://cdn.worldvectorlogo.com/logos/nodejs-icon.svg',
  mySQL: 'https://cdn.worldvectorlogo.com/logos/mysql.svg',
  passportJS: 'https://avatars0.githubusercontent.com/u/1160530?s=200&v=4',
  postgreSQL: 'https://cdn.worldvectorlogo.com/logos/postgresql.svg',
  react: 'https://cdn.worldvectorlogo.com/logos/react.svg',
  redux: 'https://cdn.worldvectorlogo.com/logos/redux.svg',
  socketIO: 'https://cdn.worldvectorlogo.com/logos/socket-io.svg',
  webpack: 'https://cdn.worldvectorlogo.com/logos/webpack-icon.svg',
  vue: 'https://cdn.worldvectorlogo.com/logos/vue-9.svg'
}